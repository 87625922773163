import React from "react"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from '../components/Button'
import '../components/scss/finished.scss'

const IndexPage = ({ data }) => (
    <Layout>
        <div className="container">
            <SEO />
            <div className="columns is-vcentered is-multiline">

                <div className="is-12 column">
                    <p>IDA Raadio & NART are happy to announce the residents for our first collaborative sound residency – Maarja Nuut (Estonia) & Cameron Stallones aka Sun Araw (USA) will be spending time together at Narva Art Residency's studio.</p>
                </div>

                <div className="is-6 column">
                    <h4>Maarja Nuut</h4>
                    <p>Maarja Nuut is a singer, violinist and a determined experimenter, someone who seeks a personal fusion between the musical traditions of long-lived times and new technologies. She’s known for utterly compelling, often hypnotic performances filled with quirky and haunting storytelling about death and the landscape.</p>
                    <p>Listen: <a href="https://soundcloud.com/maarja-nuut">soundcloud</a> <a href="https://maarjanuut.bandcamp.com/">bandcamp</a></p>
                </div>
                <div className="is-6 column image">
                    <Img style={{ maxHeight: '60vh', width: '100%' }} fixed={data.maarja.childImageSharp.fixed} />
                </div>
                
                <div className="is-6 column">
                    <h4>Sun Araw</h4>
                    <p>Sun Araw is the working title of a musical project helmed by Los Angeles-based artist Cameron Stallones. Sun Araw has released a number of well-received and consistently innovative albums. Structural and spiritual inspiration for Sun Araw comes primarily from investigation into the nature of experience by the transformative power of simple observation. </p>
                    <p>Listen: <a href="https://soundcloud.com/sun-araw">soundcloud</a></p>
                </div>
                <div className="is-6 column image">
                    <Img style={{ maxHeight: '60vh', width: '100%' }} fixed={data.sunaraw.childImageSharp.fixed} />
                </div>            
            </div>
        </div>
    </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    maarja: file(relativePath: {eq: "maarjanuut.jpg"}) {
      childImageSharp {
        fixed(width: 1000) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sunaraw: file(relativePath: {eq: "sunaraw.jpg"}) {
      childImageSharp {
        fixed(width: 1000) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`